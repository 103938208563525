<template>
	<div class="response">
		
		<el-button type="primary" icon="el-icon-arrow-left" @click="back" style="margin-bottom: 20px;">返回</el-button>
		
		<el-empty description="暂无工单" v-show="emptyShow"></el-empty>

		
		<div v-show="collapseShow" style="overflow: auto;">

			<el-collapse v-for="item in customerServiceInfoList" :key="item.sessionTime" v-model="activeNames"
				@change="handleChange" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
				<el-collapse-item name="1">
					<template v-slot:title>
						<p class="title">
							<span>工单号：{{item.sessionTime}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{status[item.status]}}</span>
							<span>提交时间：{{parseTime(item.createDatetime, '{y}年{m}月{d}日 {h}:{i}:{s}')}}</span>
						</p>
					</template>
					<el-button type="primary" @click="btn(item)">克隆</el-button>
					<div class="u-collapse-content">平台账号：{{item.username}}</div>
					<div class="u-collapse-content">小号：{{item.xiaohao}}</div>

					<div class="u-collapse-content">游戏平台：{{platform[item.platform]}}</div>
					<div class="u-collapse-content">反馈问题类型：{{customerProblemType[item.customerProblemType]}}</div>
					<div class="u-collapse-content" v-show="item.customerProblemType != 9">
						充值日期：{{parseTime(item.chargeDate, '{y}年{m}月{d}日 {h}:{i}:{s}')}}</div>
					<!-- <view class="produceBtn">
						<u-button class="produceBtn" type="primary" div="克隆" @click="btn(item)"
							throttleTime="3000"></u-button>
					</view> -->

					<div class="u-collapse-content">游戏名：{{item.gameName}}</div>
					<div class="u-collapse-content">充值金额：{{item.chargeNum}}</div>
					<div class="u-collapse-content">角色ID：{{item.roleId}}</div>
					<div class="u-collapse-content">角色名：{{item.roleName}}</div>
					<div class="u-collapse-content">区服：{{item.gameServer}}</div>
					<div class="u-collapse-content">问题描述：{{item.customerRemark}}</div>

					<div class="u-collapse-content" v-show="item.customerProblemType == 9">新游戏名：{{item.convertGameName}}
					</div>
					<div class="u-collapse-content" v-show="item.customerProblemType == 9">
						新区服：{{item.convertGameServer}}
					</div>
					<div class="u-collapse-content" v-show="item.customerProblemType == 9">新角色ID：{{item.convertRoleId}}
					</div>
					<div class="u-collapse-content" v-show="item.customerProblemType == 9">新角色名：{{item.convertRoleName}}
					</div>
					<div class="u-collapse-content" v-show="item.customerProblemType == 9">
						新充值金额：{{item.convertChargeNum}}
					</div>
					<div class="u-collapse-content" v-show="item.customerProblemType == 9">
						新充值日期：{{parseTime(item.chargeDate, '{y}年{m}月{d}日 {h}:{i}:{s}')}}</div>

					<div class="u-collapse-content" v-show="item.status == 1">客服备注：{{item.remark}}</div>
					<div class="u-collapse-content" v-show="item.status == 1">
						完成时间：{{parseTime(item.completionTime, '{y}年{m}月{d}日 {h}:{i}:{s}')}}</div>
				</el-collapse-item>
			</el-collapse>
		</div>
		<p v-if="loading" class="loading">加载中...</p>
		<p v-if="noMore" class="noMore">没有更多了</p>

	</div>
</template>

<script>
	import axios from "axios"; // 引用 axios
	import {
		encrypt
	} from "../../api/encrypt.js"
	import {
		Decrypt
	} from '../../../utils/AESUtils'
	export default {
		name: 'response',
		data() {
			return {
				activeNames: ['1'],
				emptyShow: true,
				collapseShow: false,
				pageSize: 10, // 每页显示的数量
				pageNum: 1, // 当前页数
				customerServiceInfo: [],
				customerServiceInfoList: [],
				status: ['已提交', '已完成', '处理中'],
				customerProblemType: ['其他', '线下活动返利-额外', '游戏BUG或外挂反馈', '游戏充值问题', '游戏下载问题', '抵扣券问题', '礼包领取问题', '游戏区服问题',
					'盒子下载问题', '转游申请', '', '钱包问题', '折扣解锁问题'
				],
				platform: ['米粒游', '8酷', '折扣玩'],
				totalPage: null, // 总页数
				total: null, // 总数
				loading: false,
				zkwUid: null,
			};
		},
		computed: {
			noMore() {
				return this.pageNum >= this.totalPage
			},
			disabled() {
				return this.loading || this.noMore
			}
		},
		mounted() {
			if (this.$route.query.uid) {
				this.zkwUid = this.$route.query.uid
				this.getMine()
			}
			// this.form.zkwUid = this.$route.query.uid

			// console.log(this.$route.query.uid);
		},
		methods: {
			handleChange(val) {
				console.log(val);
			},
			getMine() {
				var that = this;
				let time = new Date().getTime()
				let data = encrypt(this.zkwUid, time)
				// axios.get('/api/customerServiceZkw/problemInfo/selectUserTransactionWap', {
				axios.get(process.env.VUE_APP_BASE_API + '/customerServiceZkw/problemInfo/selectUserTransactionWap', {
						params: {
							signature: data,
							time: time,
							zkwUid: that.zkwUid,
							pageNum: this.pageNum,
							pageSize: this.pageSize,
						}
					})
					.then(
						function(response) {
							// console.log(JSON.parse(Decrypt(response.data)));
							that.customerServiceInfo = JSON.parse(Decrypt(response.data))
							console.log(that.customerServiceInfo);
							that.total = that.customerServiceInfo.total
							that.totalPage = Math.ceil(that.total / that.pageSize) // 求出总页数，向上取整
							if (that.pageNum === 1) {
								that.customerServiceInfoList = that.customerServiceInfo.rows
							} else {
								that.customerServiceInfoList.push(...that.customerServiceInfo.rows) // 在列表后面新增数据
							}

							if (that.customerServiceInfo.rows.length !== 0) {
								that.emptyShow = false
								that.collapseShow = true
							} else {
								that.emptyShow = true
								that.collapseShow = false
							}
						}
					)
					.catch(function(error) {
						console.log(error);
						that.$message.error('服务正在升级，请稍后再试');
					});
			},
			load() {
				this.loading = true
				this.pageNum = ++this.pageNum
				setTimeout(() => {
					this.getMine()
					this.loading = false
				}, 500)
			},

			btn(item) {
				console.log(item);
				this.$router.replace({
					name: '客服咨询',
					params: {
						item: JSON.stringify(item),
						uid: this.zkwUid
					}
				})
			},
			back() {
				this.$router.push({
					path: '/customerServiceConsultation',
					query: {
						uid: this.zkwUid
					}
				})
			}
		}
	}
</script>

<style scoped>
	.response {
		padding: 10px;
		display: flex;
		flex-direction: column;
	}

	.title {
		display: flex;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.loading,
	.noMore {
		color: #fff;
		margin-top: 10px;
	}

	/deep/ .el-collapse-item__wrap {
		padding: 0px 10px;
	}

	/deep/ .el-collapse-item__header {
		padding-left: 10px;
		height: 80px;
		line-height: 30px;
		border-bottom: 1px solid #d9d9d9;
	}

	/deep/ .el-collapse-item__content {
		text-align: left;
		margin-top: 10px;
	}

	/deep/ .el-button {
		float: right;
	}
</style>