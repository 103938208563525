<template>
	<div>
		<div class="kfimg1">
			<img src="../assets/img/service.png" alt=""  @click="get_kf_img1" />
			<p @click="getMine">工单<br />状态</p>
			<!-- <p icon="el-icon-search"></p> -->
			<!-- <el-button icon="el-icon-refresh" circle @click="refresh" class="btn"></el-button> -->
		</div>
	</div>
</template>

<script>
	import router from '../router'
	export default {
		name: 'getService',
		props:{
			uid: String
		},
		methods: {
			get_kf_img1() {
				// window.location.href = 'https://work.weixin.qq.com/kfid/kfcc8e4597345b37784'
				window.open('https://work.weixin.qq.com/kfid/kfc7a830b816717c2d4')
			},
			getMine() {
				if(this.uid) {
					router.push({
						path: '/response',
						query: {
							uid: this.uid
						}
					})
				} else {
					this.$message.error('请登录盒子账号，若已登录请点击刷新按钮');
				}
				
			},
			// refresh() {
			// 	window.location.reload()
			// }
		}
	}
</script>

<style scoped>
	.kfimg1 img {
		width: 50px;
		height: 50px;
		background-color: #F0F8FF;
		border-radius: 50%;
		padding: 5px;
		position: fixed;
		right: 20px;
		bottom: 80px;
	}

	.kfimg1 p {
		width: 50px;
		height: 50px;
		background-color: #F0F8FF;
		border-radius: 50%;
		padding: 5px;
		position: fixed;
		right: 20px;
		bottom: 180px;
		text-align: center;
		/* margin: 0 auto; */
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
/* 	.kfimg1 .btn {
		width: 50px;
		height: 50px;
		background-color: #F0F8FF;
		border-radius: 50%;
		padding: 5px;
		position: fixed;
		right: 23px;
		bottom: 250px;
	} */
</style>