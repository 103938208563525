<template>
	<div class="formClass">

		<p class="anewgetuid" @click="anewGetUid">刷新</p>

		<div class="header">
			<span class="el-icon-s-promotion"></span>
			<p>折扣玩问题反馈</p>
		</div>
		<el-form ref="form" :model="form" :rules="rules" label-width="110px">
			<p class="reminder" v-show="reminder_show">
				选择 游戏平台 及 反馈问题 类型后<br>显示对应需填写的内容<br /><span>** 如登录完成以后还是提示需登录盒子 **<br />** 请点击刷新按钮 **</span>
			</p>
			<p class="text_reminder" v-show="show">请点击提交并复制按钮<br>将自动生成的模板结果发给客服反馈</p>

			<p class="clone" v-show="cloneshow">
				<span>克隆游戏平台为：{{this.form.platformLable}}</span><br />
				<span>克隆反馈问题类型为：{{this.form.problemTypes}}</span><br />
				<span>克隆游戏为：{{this.form.gameName}}</span><br />
			</p>



			<transition name="el-zoom-in-center">
				<el-form-item v-show="type_show" label="反馈问题类型" prop="customerProblemType">
					<el-select v-model="form.customerProblemType" placeholder="请选择反馈问题类型" @change="displayContent">
						<el-option v-for="dict in zwkOptionsPayType" :key="dict.value" :label="dict.label"
							:value="dict.value">
						</el-option>
					</el-select>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="date_show" label="充值日期" prop="chargeDate">
					<el-date-picker style="width: 100%;" v-model="form.chargeDate" type="date" placeholder="选择日期"
						format="YYYY年MM月DD日" value-format="YYYY-MM-DD">
					</el-date-picker>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="game_show" label="游戏平台账号" prop="username">
					<el-input v-model="form.username" placeholder="请输入账号或绑定手机号" @change="getPayGameName" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="gameName_show" :label="form.customerProblemType==9?'原游戏名':'游戏名'" prop="gameId"
					class="xx">
					<el-select v-model="form.gameId" filterable remote clearable reserve-keyword placeholder="请输入或选择游戏名"
						:remote-method="remoteMethod" @change="selectChangeGame" :loading="loading">
						<el-option v-for="item in options" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="gamePlatform_show" label="游戏平台" prop="platform">
					<!-- 					<el-select v-model="form.platform" ref="platform" placeholder="请选择游戏平台"
						@change="gamePlatformDisplay"> -->
					<el-select v-model="form.platform" filterable ref="platform" placeholder="请选择游戏平台"
						@focus="getPayGamePlatform">
						<el-option v-for="dict in gamePublicType" :key="dict.key" :label="dict.value" :value="dict.key"
							style="display: flex;align-items: center;margin-bottom: 5px;">
							<img class="optionimg" v-if="dict.key == 0" src="../../assets/img/mly.png" alt="" />
							<img class="optionimg" v-else-if="dict.key == 1" src="../../assets/img/ek.png" alt="" />
							<img class="optionimg" v-else-if="dict.key == 2" src="../../assets/img/zkw.png" alt="" />
							<span style="padding-left: 10px;">{{dict.value}}</span>
						</el-option>
					</el-select>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="xiaohao_show" label="小号" prop="xiaohao">
					<el-select v-model="form.xiaohao" filterable placeholder="请选择小号名" @focus="getxiaohao">
						<el-option v-for="item in xiaohaoOptions" :key="item.value" :label="item.value"
							:value="item.value">
						</el-option>
					</el-select>
					<!-- <el-button type="primary" icon="el-icon-search" @click="getxiaohao" style="margin-left: 5px;"></el-button> -->
				</el-form-item>
			</transition>


			<!-- 查询玩家有充值的游戏列表 -->
			<!-- <transition name="el-zoom-in-center">
				<el-form-item v-show="gameNamePay_show" :label="form.customerProblemType==9?'原游戏名':'充值游戏名'"
					prop="gameId" class="xx">
					<el-select v-model="form.gameId" filterable remote clearable reserve-keyword
						placeholder="请输入或选择充值游戏名" :remote-method="remoteMethodPay" @change="selectPayChangeGame"
						:loading="loading">
						<el-option v-for="item in pay_Options" :key="item.key" :label="item.value" :value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
			</transition> -->

			<transition name="el-zoom-in-center">
				<el-form-item v-show="show" label="联系方式" prop="contactInformation">
					<el-input v-model="form.contactInformation" placeholder="请输入联系方式" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="role_show" label="充值金额" prop="chargeNum">
					<!--					<el-input v-model="form.chargeNum" placeholder="请输入充值金额" />-->
					<el-input-number v-model="form.chargeNum" :precision="2" :step="1" :min="0" placeholder="请输入充值金额"
						style="width: 200px"></el-input-number>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="role_show" :label="form.customerProblemType==9?'原角色ID':'角色ID'" prop="roleId">
					<el-input v-model="form.roleId" placeholder="请输入角色ID" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="role_show" :label="form.customerProblemType==9?'原角色名':'角色名'" prop="roleName">
					<el-input v-model="form.roleName" placeholder="请输入角色名" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="role_show" :label="form.customerProblemType==9?'原区服':'区服'" prop="gameServer">
					<el-input v-model="form.gameServer" placeholder="请输入区服" />
				</el-form-item>
			</transition>

			<!-- 转游模块 -->
			<el-divider v-show="convert_show" content-position="center">转玩</el-divider>
			<transition name="el-zoom-in-center">
				<el-form-item v-show="convert_show" label="新游戏名" prop="convertGameId" class="xx">
					<el-select v-model="form.convertGameId" filterable remote clearable reserve-keyword
						placeholder="请输入或选择游戏名" :remote-method="remoteMethod" @change="selectChangeGame"
						:loading="loading">
						<el-option v-for="item in convert_Options" :key="item.key" :label="item.value"
							:value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="convert_show" label="新角色ID" prop="convertRoleId">
					<el-input v-model="form.convertRoleId" placeholder="请输入角色ID" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="convert_show" label="新角色名" prop="convertRoleName">
					<el-input v-model="form.convertRoleName" placeholder="请输入角色名" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="convert_show" label="新区服" prop="convertGameServer">
					<el-input v-model="form.convertGameServer" placeholder="请输入区服" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="convert_show" label="新游充值金额" prop="chargeNum">
					<!--					<el-input v-model="form.chargeNum" placeholder="请输入充值金额" />-->
					<el-input-number v-model="form.convertChargeNum" :precision="2" :step="1" :min="0"
						placeholder="请输入充值金额" style="width: 200px"></el-input-number>
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="convert_show" label="新游充值日期" prop="chargeDate">
					<el-date-picker style="width: 100%;" v-model="form.chargeDate" type="date" placeholder="选择日期"
						format="YYYY年MM月DD日" value-format="YYYY-MM-DD">
					</el-date-picker>
				</el-form-item>
			</transition>
			<!-- **************************************************************** -->

			<transition name="el-zoom-in-center">
				<el-form-item v-show="show" label="问题描述" prop="customerRemark">
					<el-input v-model="form.customerRemark" type="textarea" placeholder="请输入问题描述"
						:autosize="{ minRows: 2, maxRows: 15}" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="show" label="模板结果" prop="remark">
					<el-input id="remark" v-model="info.remark" type="textarea" placeholder="填写以上信息自动生成模板结果"
						:autosize="{ minRows: 2, maxRows: 15}" />
				</el-form-item>
			</transition>

			<transition name="el-zoom-in-center">
				<el-form-item v-show="show" label=" 工单号" prop="sessionTime">
					<el-input v-model="form.sessionTime" placeholder="自动生成工单号" disabled />
				</el-form-item>
			</transition>
		</el-form>

		<transition name="el-zoom-in-center">
			<div class="dialog-footer" v-show="show">
				<!--				<el-button type="primary" native-type="button" data-clipboard-action="copy" data-clipboard-target="#remark" id="copybtn" @click="generateRemake">提交并复制</el-button>-->
				<el-button type="primary" native-type="button" @click="generateRemake">提交并复制</el-button>
				<!--        <span class='c-btn' @click="copy" id="copy" ref="copy" :data-clipboard-text="remark"  ></span>-->
				<el-button @click="cancel">重 置</el-button>
			</div>
		</transition>

		<getService :uid="form.zkwUid"></getService>
	</div>
</template>
<style>
	.formClass {
		width: 310px;
		margin: auto;
	}

	.header {
		display: flex;
		background-color: #409eff;
		border-radius: 0 0 10px 10px;
		height: 70px;
		margin-bottom: 20px;
		color: #fff;
	}

	.header span {
		width: 35%;
		line-height: 70px;
		font-size: 40px;
	}

	.header p {
		font-size: 17px;
		line-height: 70px;
	}

	.el-button-group {
		width: 310px;
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
	}

	.reminder {
		color: #409eff;
		background-color: aliceblue;
		margin-bottom: 20px;
		font-size: 17px;
		padding: 4px;
		border-radius: 5px;
		height: 160px;
		line-height: 40px;
	}
	
	.reminder span {
		color: #ff3a3a;
		font-size: 15px;
	}

	.text_reminder {
		color: #ff3a3a;
		background-color: #ffd0d0;
		margin-bottom: 20px;
		font-size: 18px;
		padding: 4px;
		border-radius: 5px;
		height: 80px;
		line-height: 40px;
	}

	.clone {
		color: #409cff;
		background-color: #f0fffb;
		margin-bottom: 20px;
		font-size: 15px;
		padding: 4px;
		border-radius: 5px;
		height: 80px;
		line-height: 27px;
	}

	.dialog-footer {
		margin-bottom: 30px;
	}

	.cp-dialog-footer {
		margin-bottom: 15px;
		display: flex;
		justify-content: flex-end;
	}

	.c-btn {
		cursor: pointer;
		width: 1px;
		height: 1px;
		opacity: 0;
		display: inline-block;
	}

	.message-logout {
		width: 310px;
	}

	element.style {
		min-height: 83px;
	}

	/* 	.el-message-box {
		width: 25%;
	} */

	/* 	/deep/ .el-form-item__label {
		color: white;
	} */

	/* 	/deep/ .el-input__inner {
		background: #222048;
		border: 1px solid #9698A4;
	} */

	/* 	/deep/ .el-textarea__inner {
		background: #222048;
		border: 1px solid #9698A4;
	} */

	/deep/ .el-form-item__content {
		display: flex;
	}

	.optionimg {
		width: 35px;
	}

	.anewgetuid {
		width: 50px;
		height: 50px;
		background-color: #F0F8FF;
		border-radius: 50%;
		padding: 5px;
		position: fixed;
		right: 20px;
		bottom: 270px;
		text-align: center;
		/* margin: 0 auto; */
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
	}
</style>

<script>
	import axios from "axios"; // 引用 axios
	import useClipboard from 'vue-clipboard3'
	// import Clipboard from 'clipboard'
	import {
		Decrypt
	} from '../../../utils/AESUtils'
	// import CryptoJS from 'crypto-js/crypto-js'
	import getService from '../../components/getService.vue'
	export default {
		name: "customerServiceConsultation",
		components: {
			getService
		},
		data() {
			// 游戏名必填
			var checkGameId = (rule, value, callback) => {
				if (!value && this.form.customerProblemType <= 3) {
					return callback(new Error('游戏名不能为空'));
				} else {
					callback();
				}
			};
			var checkConvertGameId = (rule, value, callback) => {
				if (!value && this.form.customerProblemType == 9) {
					return callback(new Error('游戏名不能为空'));
				} else {
					callback();
				}
			};
			// 平台账号必填，4到16位字母加数字组合
			var checkUsername = (rule, value, callback) => {
				let reg_username = /^[a-zA-Z0-9_-]{4,16}$/
				if (!value) {
					return callback(new Error('平台账号不能为空'));
				}
				setTimeout(() => {
					if (!reg_username.test(value)) {
						callback(new Error('请输入4到16位字母加数字组合'));
					} else {
						callback();
					}
				}, 1000)
			};

			var checkPlatform = (rule, value, callback) => {
				if (!value && this.form.customerProblemType == 1) {
					return callback(new Error('游戏平台不能为空'));
				} else {
					callback();
				}
			};

			// 角色ID必填
			// var checkRoleId = (rule, value, callback) => {
			//   if (!value && this.form.customerProblemType <= 3) {
			//     return callback(new Error('角色ID不能为空'));
			//   } else {
			//     callback();
			//   }
			// };
			// 角色名必填
			var checkRoleName = (rule, value, callback) => {
				if (!value && this.form.customerProblemType <= 3 && this.form.platform != 2) {
					return callback(new Error('角色名不能为空'));
				} else {
					callback();
				}
			};
			var checkConvertRoleName = (rule, value, callback) => {
				if (!value && this.form.customerProblemType == 9 && this.form.platform != 2) {
					return callback(new Error('角色名不能为空'));
				} else {
					callback();
				}
			};
			// 区服必填
			var checkGameServer = (rule, value, callback) => {
				if (!value && this.form.customerProblemType <= 3 && this.form.platform != 2) {
					return callback(new Error('区服不能为空'));
				} else {
					callback();
				}
			};
			var checkConvertGameServer = (rule, value, callback) => {
				if (!value && this.form.customerProblemType == 9 && this.form.platform != 2) {
					return callback(new Error('区服不能为空'));
				} else {
					callback();
				}
			};
			return {
				loading: false,
				show: false,
				type_show: true,
				gameName_show: false,
				xiaohao_show: false,
				// gameNamePay_show: false, // 控制玩家有充值的游戏列表搜索框显示与隐藏
				game_show: false,
				date_show: false,
				role_show: false,
				reminder_show: true,
				convert_show: false,
				gamePlatform_show: false, // 控制游戏平台显示与隐藏
				cloneshow: false,
				number: 0,
				// 表单参数
				form: {
					sessionTime: null,
					username: null,
					gameId: null,
					gameServer: null,
					roleId: null,
					roleName: null,
					chargeNum: 0,
					customerProblemType: null,
					gameName: null,
					contactInformation: null,
					customerRemark: null,
					chargeDate: null,
					// 转游模块
					convertGameId: null,
					convertGameName: null,
					convertGameServer: null,
					convertRoleId: null,
					convertRoleName: null,
					convertChargeNum: 0,

					// 游戏平台（2024/06/21新增）
					platform: null,
					xiaohao: null,
					zkwUid: null,
					// zkwUid: null
				},
				problemTypes: null,
				platformLable: null, // 游戏平台 lable
				info: {
					remark: null,
				},
				clipboard: null,
				gameList: [],
				// gamePayList: [],
				convert_GameList: [],
				options: [],
				pay_Options: [],
				convert_Options: [],
				xiaohaoOptions: [],
				rules: {
					username: [{
						validator: checkUsername,
						trigger: 'blur'
					}],
					gameId: [{
						validator: checkGameId,
						trigger: 'blur'
					}],
					platform: [{
						validator: checkPlatform,
						trigger: 'blur'
					}],
					convertGameId: [{
						validator: checkConvertGameId,
						trigger: 'blur'
					}],
					contactInformation: [{
						required: true,
						message: "联系方式不能为空",
						trigger: "blur"
					}],
					customerProblemType: [{
						required: true,
						message: "反馈问题类型不能为空",
						trigger: "change"
					}],
					// roleId: [{
					//   validator: checkRoleId,
					//   trigger: 'blur'
					// }],
					roleName: [{
						validator: checkRoleName,
						trigger: 'blur'
					}],
					convertRoleName: [{
						validator: checkConvertRoleName,
						trigger: 'blur'
					}],
					gameServer: [{
						validator: checkGameServer,
						trigger: 'blur'
					}],
					convertGameServer: [{
						validator: checkConvertGameServer,
						trigger: 'blur'
					}],
					// chargeNum: [{
					//   validator: checkChargeNum,
					//   trigger: 'blur'
					// }],
				},

				// 游戏平台
				gamePlatform: [{
					value: '0',
					label: '米粒游'
				}, {
					value: '1',
					label: '8酷'
				}, {
					value: '2',
					label: '折扣玩'
				}, ],

				resType: ['其他', '返利问题', '游戏问题', '游戏充值问题', '下载问题', '抵扣券问题', '礼包领取问题', '游戏区服问题',
					'盒子下载问题', '转游申请', '', '钱包问题', '折扣解锁问题'
				],

				platformType: ['米粒游', '8酷', '折扣玩'],

				// 选中平台赋值不同类型
				publicType: [],

				// 选中类型赋值不同的游戏平台
				gamePublicType: [],

				zkwGamePublicType: [{
					value: '折扣玩',
					key: 2
				}],

				// 游戏问题，返利问题，下载问题

				zwkOptionsPayType: [{
						value: '12',
						label: '折扣解锁问题'
					},
					{
						value: '11',
						label: '钱包问题'
					},
					{
						value: '1',
						label: '返利问题'
					},
					{
						value: '2',
						label: '游戏问题'
					},
					// {
					// 	value: '3',
					// 	label: '盒子充值问题'
					// },
					// {
					// 	value: '9',
					// 	label: '转游申请'
					// }, 
					{
						value: '4',
						label: '下载问题'
					},
					// {
					// 	value: '5',
					// 	label: '抵扣券问题'
					// },
					// {
					// 	value: '6',
					// 	label: '礼包领取问题'
					// }, 
					// {
					// 	value: '7',
					// 	label: '游戏区服问题'
					// }, 
					// {
					// 	value: '8',
					// 	label: '盒子下载问题'
					// }, 
					{
						value: '0',
						label: '其他'
					},

				],

				optionsPayType: [{
						value: '0',
						label: '其他'
					}, {
						value: '1',
						label: '线下活动返利-额外'
					}, {
						value: '2',
						label: '游戏BUG或外挂反馈'
					}, {
						value: '3',
						label: '游戏充值问题'
					}, {
						value: '9',
						label: '转游申请'
					}, {
						value: '4',
						label: '游戏下载问题'
					}, {
						value: '5',
						label: '抵扣券问题'
					},
					// {
					// 	value: '6',
					// 	label: '礼包领取问题'
					// }, 
					{
						value: '7',
						label: '游戏区服问题'
					}, {
						value: '8',
						label: '盒子下载问题'
					}
				]
			};
		},
		mounted() {
			// this.$message.error('页面重进了')

			this.zkwGetGameName()

			// console.log(this.$route.query);
			// const urlParams = new URLSearchParams(window.location.search);
			// const paramValue = urlParams.get('uid');
			// console.log(paramValue);
			// this.form.zkwUid = paramValue
			// console.log(this.$route);
			if (this.$route.query.uid) {
				this.form.zkwUid = this.$route.query.uid
			}


			// console.log(JSON.parse(this.$route.params.item));
			if (this.$route.params.item != undefined) {
				let items = JSON.parse(this.$route.params.item)
				let uid = this.$route.params.uid

				this.form.zkwUid = uid

				this.cloneshow = true
				this.gamePlatform_show = false
				this.type_show = false

				this.form.customerProblemType = items.customerProblemType
				this.problemTypes = this.resType[items.customerProblemType]
				this.displayContent()

				this.form.platform = items.platform // 游戏平台 value
				this.form.platformLable = this.platformType[items.platform] // 游戏平台 lable



				this.form.zkwUsername = items.zkwUsername
				this.form.gameId = items.gameId
				this.form.gameName = items.gameName
				// this.form.chargeDate = uni.$u.timeFormat(items.chargeDate, 'yyyy-mm-dd')
				this.form.username = items.username
				this.form.xiaohao = items.xiaohao
				this.form.contactInformation = items.contactInformation
				this.form.chargeNum = items.chargeNum
				this.form.roleId = items.roleId
				this.form.roleName = items.roleName
				this.form.gameServer = items.gameServer
				// this.form.customerRemark = items.customerRemark
				this.form.convertGameId = items.convertGameId
				this.form.convertGameName = items.convertGameName
				this.form.convertGameServer = items.convertGameServer
				this.form.convertRoleId = items.convertRoleId
				this.form.convertRoleName = items.convertRoleName
				this.form.convertChargeNum = items.convertChargeNum
			}
		},

		methods: {
			remoteMethod(query) {
				if (query !== '') {
					this.loading = true;
					this.options = this.gameList.filter(item => {
						this.loading = false;
						return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
					});
					this.convert_Options = this.convert_GameList.filter(item => {
						this.loading = false;
						return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
					});
				} else {
					// this.options = this.gameList;
					this.loading = false;
				}
			},
			
			anewGetUid() {
				// this.$message.error(this.$route.query.uid)
				if (this.$route.query.uid) {
					this.form.zkwUid = this.$route.query.uid
				}
			},

			// remoteMethodPay(query) {
			// 	if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
			// 		if (query !== '') {
			// 			this.loading = true;
			// 			this.pay_Options = this.gamePayList.filter(item => {
			// 				this.loading = false;
			// 				return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
			// 			});
			// 			// this.convert_Options = this.convert_GameList.filter(item => {
			// 			// 	this.loading = false;
			// 			// 	return item.value.toLowerCase().indexOf(query.toLowerCase()) > -1;
			// 			// });
			// 		} else {
			// 			// this.options = this.gameList;
			// 			this.loading = false;
			// 		}
			// 	} else {
			// 		this.$message({
			// 			message: '请先输入用户名',
			// 			type: 'warning'
			// 		});
			// 	}

			// },

			// 游戏平台账号输入框失去焦点或用户按下回车时获取账号充值过的游戏名
			// getPayGameName() {
			// this.displayContent()
			// if (this.form.gamePlatform == 0) {
			// 	if (this.form.customerProblemType == 1) {
			// 		if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
			// 			this.mlyGetPayGameName()
			// 		}
			// 	}
			// } else if (this.form.gamePlatform == 1) {
			// 	if (this.form.customerProblemType == 1) {
			// 		if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
			// 			this.bkGetPayGameName()
			// 		}
			// 	}
			// }

			// },

			getxiaohao() {
				// console.log(111);
				if (this.form.platform == 0) {
					this.mlyGetXiaohao()
				} else if (this.form.platform == 1) {
					this.bkGetXiaohao()
				} else {
					this.$message.error('请先选择游戏平台后可查询小号')
				}
			},

			selectChangeGame() {
				this.options = this.gameList;
				this.convert_Options = this.convert_GameList;
			},

			// 游戏输入框失去焦点时查询该玩家充值过的游戏平台
			getPayGamePlatform() {
				console.log(111);
				let _this = this
				if (this.form.customerProblemType == 1) {
					if (this.form.username != '' && this.form.gameId != '' && this.form.username != null && this.form
						.gameId != null) {
						// axios.get('/api/customerServiceZkw/problemInfo/selectGamePlatformList', {
						axios.get(process.env.VUE_APP_BASE_API +
						'/customerServiceZkw/problemInfo/selectGamePlatformList', {
							params: {
								username: _this.form.username,
								gameId: _this.form.gameId,
							}
						}).then((res) => {
							let data = JSON.parse(Decrypt(res.data))
							if (data.length == 0) {
								_this.$message.error('该游戏没有在任何平台充值过')
							} else {
								_this.gamePublicType = data
							}

						})
					} else {
						this.$message.error('必须填写用户名和游戏名才可搜寻充值过的游戏平台')
					}
				}
			},

			// selectPayChangeGame() {
			// 	this.pay_Options = this.gamePayList;
			// },
			// 取消按钮
			cancel() {
				this.reset();
			},

			// copy(e) {
			//   e.preventDefault()
			//   this.$forceUpdate()
			//   let clipboard = this.clipboard
			//   clipboard.on('success', function () {
			//     console.log('复制成功')
			//     clipboard.destroy()
			//   });
			//
			//   clipboard.on('error', function () {
			//     console.log('复制失败')
			//     clipboard.destroy()
			//   });
			// },

			//提交表单信息
			submitForm() {
				// console.log(this.form);
				var _this = this;
				_this.form.sessionTime = 'zc' + new Date().getTime() + '';
				axios.post(process.env.VUE_APP_BASE_API + '/customerServiceZkw/problemInfo/postCustomerProblemWap', _this
						.form)
					.then(function(response) {
						console.log(response);
						if (response.data.code == 200) {
							// if (_this.form.customerProblemType == 4 || _this.form.customerProblemType == 5 || _this
							// 	.form.customerProblemType == 6 || _this.form.customerProblemType == 7) {
							if (_this.form.customerProblemType == 4 || _this.form.customerProblemType == 5 || _this
								.form.customerProblemType == 7) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +

									"\n游戏ID：" + _this.form.gameId +
									"\n游戏名：" + _this.form.gameName +
									"\n游戏平台账号：" + _this.form.username +
									"\n问题描述：" + _this.form.customerRemark +

									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							} else if (_this.form.customerProblemType == 0 || _this.form.customerProblemType == 2 ||
								_this.form.customerProblemType == 3) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +

									"\n游戏ID：" + _this.form.gameId +
									"\n游戏名：" + _this.form.gameName +
									"\n游戏平台账号：" + _this.form.username +
									"\n区服：" + _this.form.gameServer +
									"\n角色ID：" + _this.form.roleId +
									"\n角色名：" + _this.form.roleName +

									"\n充值金额：" + _this.form.chargeNum +
									"\n问题描述：" + _this.form.customerRemark +

									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							} else if (_this.form.customerProblemType == 1) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +

									"\n游戏ID：" + _this.form.gameId +
									"\n游戏名：" + _this.form.gameName +
									"\n游戏平台账号：" + _this.form.username +
									"\n区服：" + _this.form.gameServer +
									"\n角色ID：" + _this.form.roleId +
									"\n角色名：" + _this.form.roleName +

									"\n充值金额：" + _this.form.chargeNum +
									"\n充值日期：" + _this.form.chargeDate +
									"\n问题描述：" + _this.form.customerRemark +

									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							} else if (_this.form.customerProblemType == 8) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +
									"\n游戏平台账号：" + _this.form.username +
									"\n问题描述：" + _this.form.customerRemark +
									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							} else if (_this.form.customerProblemType == 9) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +

									"\n游戏平台账号：" + _this.form.username +
									"\n原游戏ID：" + _this.form.gameId +
									"\n原游戏名：" + _this.form.gameName +
									"\n原区服：" + _this.form.gameServer +
									"\n原角色ID：" + _this.form.roleId +
									"\n原角色名：" + _this.form.roleName +
									"\n原充值金额：" + _this.form.chargeNum +

									"\n转玩：" +

									"\n游戏平台账号：" + _this.form.username +
									"\n新游戏ID：" + _this.form.convertGameId +
									"\n新游戏名：" + _this.form.convertGameName +
									"\n新区服：" + _this.form.convertGameServer +
									"\n新角色ID：" + _this.form.convertRoleId +
									"\n新角色名：" + _this.form.convertRoleName +
									"\n新游充值金额：" + _this.form.convertChargeNum +
									"\n新游充值日期：" + _this.form.chargeDate +

									"\n问题描述：" + _this.form.customerRemark +

									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							} else if (_this.form.customerProblemType == 11) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +
									"\n游戏平台账号：" + _this.form.username +
									"\n游戏ID：" + _this.form.gameId +
									"\n游戏名：" + _this.form.gameName +
									"\n问题描述：" + _this.form.customerRemark +
									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							} else if (_this.form.customerProblemType == 12) {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + _this.form.contactInformation +
									"\n游戏平台：" + _this.platformLable +
									"\n反馈问题类型：" + _this.problemTypes +
									"\n游戏平台账号：" + _this.form.username +
									"\n游戏ID：" + _this.form.gameId +
									"\n游戏名：" + _this.form.gameName +
									"\n问题描述：" + _this.form.customerRemark +
									"\n工单号：" + _this.form.sessionTime;

								useClipboard().toClipboard(_this.info.remark);
							}
						} else if (response.data.code == 409) {
							var resData = response.data.data;
							_this.form.sessionTime = resData.sessionTime;
							if (resData.customerProblemType == 0) {
								resData.customerProblemType = '其他'
							} else if (resData.customerProblemType == 1) {
								resData.customerProblemType = '线下活动返利-额外'
							} else if (resData.customerProblemType == 2) {
								resData.customerProblemType = '游戏BUG或外挂反馈'
							} else if (resData.customerProblemType == 3) {
								resData.customerProblemType = '游戏充值问题'
							} else if (resData.customerProblemType == 9) {
								resData.customerProblemType = '转游申请'
							} else if (resData.customerProblemType == 4) {
								resData.customerProblemType = '游戏下载问题'
							} else if (resData.customerProblemType == 5) {
								resData.customerProblemType = '抵扣券问题'
							} else if (resData.customerProblemType == 7) {
								resData.customerProblemType = '游戏区服问题'
							} else if (resData.customerProblemType == 8) {
								resData.customerProblemType = '盒子下载问题'
							} else if (resData.customerProblemType == 11) {
								resData.customerProblemType = '钱包问题'
							} else if (resData.customerProblemType == 12) {
								resData.customerProblemType = '折扣解锁问题'
							}
							// else if (resData.customerProblemType == 6) {
							// 	resData.customerProblemType = '礼包领取问题'
							// }

							if (resData.customerProblemType == '其他' || resData.customerProblemType == '线下活动返利-额外' ||
								resData.customerProblemType == '游戏BUG或外挂反馈' || resData.customerProblemType ==
								'游戏充值问题' || resData.customerProblemType == '游戏下载问题' || resData.customerProblemType ==
								'抵扣券问题' || resData.customerProblemType ==
								'游戏区服问题' || resData.customerProblemType == '盒子下载问题' || resData.customerProblemType ==
								'钱包问题' || resData.customerProblemType == '折扣解锁问题') {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + resData.contactInformation +
									"\n游戏平台：" + _this.platformType[resData.platform] +
									"\n反馈问题类型：" + resData.customerProblemType +
									"\n游戏ID：" + resData.gameId +
									"\n游戏名：" + resData.gameName +
									"\n游戏平台账号：" + resData.username +
									"\n区服：" + resData.gameServer +
									"\n角色ID：" + resData.roleId +
									"\n角色名：" + resData.roleName +

									"\n充值金额：" + resData.chargeNum +
									"\n充值日期：" + resData.chargeDate +

									"\n\n该工单未处理，请粘贴给客服\n" +
									"\n工单号：" + resData.sessionTime;

								useClipboard().toClipboard(_this.info.remark);

								_this.$alert('您有正在处理的工单，可将当前模板结果粘贴给客服询问处理进度，如需提交新工单，请联系客服处理完成此工单在试！', "警告", {
									confirmButtonText: "确定",
									type: "warning",
									center: true,
									customClass: 'message-logout'
								})

							} else if (resData.customerProblemType == '转游申请') {
								_this.info.remark = "日期：" + new Date().getFullYear() + "/" + (new Date()
										.getMonth() + 1) + "/" + new Date().getDate() +
									"\n联系方式：" + resData.contactInformation +
									"\n游戏平台：" + _this.platformType[resData.platform] +
									"\n反馈问题类型：" + resData.customerProblemType +
									"\n原游戏ID：" + resData.gameId +
									"\n原游戏名：" + resData.gameName +
									"\n游戏平台账号：" + resData.username +
									"\n原区服：" + resData.gameServer +
									"\n原角色ID：" + resData.roleId +
									"\n原角色名：" + resData.roleName +
									"\n充值金额：" + resData.chargeNum +
									"\n转玩：" +
									"\n游戏平台账号：" + resData.username +
									"\n新游戏ID：" + resData.convertGameId +
									"\n新游戏名：" + resData.convertGameName +
									"\n新区服：" + resData.convertGameServer +
									"\n新角色ID：" + resData.convertRoleId +
									"\n新角色名：" + resData.convertRoleName +
									"\n新游充值金额：" + resData.convertChargeNum +
									"\n新游充值日期：" + resData.chargeDate +

									"\n\n该工单未处理，请粘贴给客服\n" +
									"\n工单号：" + resData.sessionTime;

								useClipboard().toClipboard(_this.info.remark);

								_this.$alert('您有正在处理的工单，可将当前模板结果粘贴给客服询问处理进度，如需提交新工单，请联系客服处理完成此工单在试！', "警告", {
									confirmButtonText: "确定",
									type: "warning",
									center: true,
									customClass: 'message-logout'
								})
							}
							_this.$message({
								message: response.data.msg,
								type: 'success',
							});
						} else if (response.data.code == 500) {
							_this.form.sessionTime = null
							_this.$alert(response.data.msg, "警告", {
								confirmButtonText: "确定",
								type: "warning",
								center: true,
								customClass: 'message-logout'
							})
						}
					}).catch(function(error) {
						console.log(error)
						_this.$message.error('提交失败，请稍后再试');
					});
			},
			generateRemake() {
				this.gameList.forEach(item => {
					if (item.key == this.form.gameId) {
						this.form.gameName = item.value
					}
					return item.key == this.form.gameId
				});
				// this.gamePayList.forEach(item => {
				// 	if (item.key == this.form.gameId) {
				// 		this.form.gameName = item.value
				// 	}
				// 	return item.key == this.form.gameId
				// });
				this.convert_GameList.forEach(item => {
					if (item.key == this.form.convertGameId) {
						this.form.convertGameName = item.value
					}
					return item.key == this.form.convertGameId
				});
				this.publicType.filter(item => {
					if (item.value == this.form.customerProblemType) {
						this.problemTypes = item.label
					}
					return item.value == this.form.customerProblemType
				});
				this.gamePlatform.filter(item => {
					if (item.value == this.form.platform) {
						this.platformLable = item.value
					}
					return item.value == this.form.platform
				});
				this.$refs["form"].validate(valid => {
					if (valid) {
						this.$confirm('填写信息确认无误吗?', "警告", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
							customClass: 'message-logout'
						}).then(() => {
							this.submitForm();
							// this.copy();
						}).then(() => {
							this.$message({
								message: '备注已复制到剪切板',
								type: 'success',
								center: true
							});
						})
					}
				})
			},

			// 表单重置
			reset() {
				this.form = {
					sessionTime: null,
					username: null,
					gameId: null,
					gameServer: null,
					roleId: null,
					roleName: null,
					chargeNum: null,
					customerProblemType: null,
					gameName: null,
					customerRemark: null,
					chargeDate: null,
					// 转游模块
					convertGameId: null,
					convertGameName: null,
					convertGameServer: null,
					convertRoleId: null,
					convertRoleName: null,
					convertChargeNum: 0,
					platform: null,
					xiaohao: null,
				};
				if (Object.keys(this.$route.query).length != 0) {
					this.form.zkwUid = this.$route.query.uid
				}

				if (Object.keys(this.$route.params).length != 0) {
					this.form.zkwUid = this.$route.params.uid
				}

				this.info.remark = null;
				this.show = false
				this.gameName_show = false
				this.xiaohao_show = false
				// this.gameNamePay_show = false
				this.game_show = false
				this.role_show = false
				this.reminder_show = true
				this.date_show = false
				this.convert_show = false
				this.type_show = true
				this.cloneshow = false
				this.gamePlatform_show = false
				this.publicType = []
				this.gamePublicType = []
				this.xiaohaoOptions = []
			},
			// 反馈问题类型选择监听
			displayContent() {
				// if (this.form.platform == 0) {
				// 	if (this.form.customerProblemType == 0 || this.form.customerProblemType == 2 || this.form
				// 		.customerProblemType == 3 || this.form.customerProblemType == 4 || this.form.customerProblemType ==
				// 		5 || this.form
				// 		.customerProblemType == 7 || this.form.customerProblemType == 9) {
				// 		this.mlyGetGameName()

				// 	}
				// } else if (this.form.platform == 1) {
				// 	if (this.form.customerProblemType == 0 || this.form.customerProblemType == 2 || this.form
				// 		.customerProblemType == 3 || this.form.customerProblemType == 4 || this.form.customerProblemType ==
				// 		5 || this.form
				// 		.customerProblemType == 7 || this.form.customerProblemType == 9) {
				// 		this.bkGetGameName()
				// 	}
				// } else {
				// 	this.zkwGetGameName()
				// }

				// if (this.form.platform == 0 && this.form.customerProblemType == 1) {
				// 	if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
				// 		this.mlyGetPayGameName()
				// 	}
				// } else if (this.form.platform == 1 && this.form.customerProblemType == 1) {
				// 	if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
				// 		this.bkGetPayGameName()
				// 	}
				// }

				// 游戏平台为折扣玩时无需填写用户名
				// if (this.form.platform == 2) {
				// 	this.game_show = false
				// } else {
				// 	this.game_show = true
				// }

				if (this.form.zkwUid == null || this.form.zkwUid == '' || this.form.zkwUid == undefined) {
					this.$message.error('请登录盒子账号，若已登录请点击刷新按钮');
					this.anewGetUid()
				} else {
					if (this.gamePublicType.length != 0 || this.form.platform != null) {
						this.form.platform = null
						this.gamePublicType = []
					}

					if (this.form.customerProblemType == 4 || this.form.customerProblemType == 5 || this.form
						.customerProblemType == 7) {

						this.form.platform = 2

						this.show = true

						if (this.$route.params.item != undefined) {
							this.gameName_show = false
						} else {
							this.gameName_show = true
						}


						// this.gameNamePay_show = false
						this.game_show = true
						this.xiaohao_show = false
						this.role_show = false
						this.reminder_show = false
						this.date_show = false
						this.convert_show = false

						this.gamePlatform_show = false
					} else if (this.form.customerProblemType == 0 || this.form
						.customerProblemType == 2 || this.form.customerProblemType == 3) {

						this.form.platform = 2

						this.show = true

						if (this.$route.params.item != undefined) {
							this.gameName_show = false
						} else {
							this.gameName_show = true
						}

						// this.gameNamePay_show = false
						this.game_show = true


						// if (this.form.platform == 2) {
						// 	this.role_show = false
						// } else {
						// 	this.role_show = true
						// }

						this.role_show = true
						this.xiaohao_show = false
						this.reminder_show = false
						this.date_show = false
						this.convert_show = false

						this.gamePlatform_show = false
					} else if (this.form.customerProblemType == 1) {
						this.show = true
						// this.gameNamePay_show = false

						if (this.$route.params.item != undefined) {
							this.gameName_show = false
						} else {
							this.gameName_show = true
						}

						this.xiaohao_show = true
						this.game_show = true
						this.role_show = true
						this.reminder_show = false
						this.date_show = true
						this.convert_show = false

						this.gamePlatform_show = true
					} else if (this.form.customerProblemType == 8) {
						this.show = true
						this.gameName_show = false
						this.xiaohao_show = false
						// this.gameNamePay_show = false
						this.game_show = true
						this.role_show = false
						this.reminder_show = false
						this.date_show = false
						this.convert_show = false
					} else if (this.form.customerProblemType == 9) {
						this.show = true

						if (this.$route.params.item != undefined) {
							this.gameName_show = false
						} else {
							this.gameName_show = true
						}

						this.xiaohao_show = false
						// this.gameNamePay_show = false
						this.game_show = true
						this.role_show = true
						this.reminder_show = false
						this.date_show = false
						this.convert_show = true
					} else if (this.form.customerProblemType == 11 || this.form.customerProblemType == 12) {

						this.form.platform = 2

						this.show = true

						if (this.$route.params.item != undefined) {
							this.gameName_show = false
						} else {
							this.gameName_show = true
						}

						// this.gameNamePay_show = false
						this.game_show = true
						this.xiaohao_show = false
						this.role_show = false
						this.reminder_show = false
						this.date_show = false
						this.convert_show = false

						this.gamePlatform_show = false
					} else {
						this.type_show = true
						this.show = false
						this.gameName_show = false
						this.xiaohao_show = false
						// this.gameNamePay_show = false
						this.game_show = false
						this.role_show = false
						this.reminder_show = false
						this.date_show = false
						this.convert_show = false

						this.gamePlatform_show = false
					}
				}


			},
			// 游戏平台选择监听
			// gamePlatformDisplay() {
			// 	// console.log(this.form);
			// 	// if (this.form.zkwUid == null || this.form.zkwUid == '' || this.form.zkwUid == undefined) {
			// 	// 	this.$message.error('请先在盒子中登录');
			// 	// } else {

			// 	if (this.form.customerProblemType) {

			// 		this.form.customerProblemType = null
			// 		this.displayContent()
			// 	}

			// 	this.type_show = true

			// 	if (this.form.platform == 2) {
			// 		this.publicType = this.zwkOptionsPayType
			// 	} else {
			// 		this.publicType = this.optionsPayType
			// 	}

			// 	// 特殊情况：玩家选择完平台及问题类型后在重新选择
			// 	if (this.form.platform == 0) { // 如果平台为米粒游，并且问题类型不为空，并且问题类型不为 1 时 ，走以下判断
			// 		if (this.form.customerProblemType != null && this.form.customerProblemType != '' && this.form
			// 			.customerProblemType != undefined) {
			// 			if (this.form.customerProblemType == 0 || this.form.customerProblemType == 2 || this.form
			// 				.customerProblemType == 3 || this.form.customerProblemType == 4 || this.form
			// 				.customerProblemType ==
			// 				5 || this.form
			// 				.customerProblemType == 7 || this.form.customerProblemType == 9) {
			// 				this.mlyGetGameName()

			// 			}
			// 		}
			// 	} else if (this.form.platform == 1) { // 如果平台为8酷，并且问题类型不为空，并且问题类型不为 1 时 ，走以下判断
			// 		if (this.form.customerProblemType != null && this.form.customerProblemType != '' && this.form
			// 			.customerProblemType != undefined) {
			// 			if (this.form.customerProblemType == 0 || this.form.customerProblemType == 2 || this.form
			// 				.customerProblemType == 3 || this.form.customerProblemType == 4 || this.form
			// 				.customerProblemType ==
			// 				5 || this.form
			// 				.customerProblemType == 7 || this.form.customerProblemType == 9) {
			// 				this.bkGetGameName()

			// 			}
			// 		}
			// 	} else {
			// 		if (this.form.customerProblemType != null && this.form.customerProblemType != '' && this.form
			// 			.customerProblemType != undefined) {
			// 			this.zkwGetGameName()
			// 		}
			// 	}

			// 	if (this.form.platform == 0 && this.form.customerProblemType == 1) {
			// 		if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
			// 			this.mlyGetPayGameName()
			// 		}
			// 	} else if (this.form.platform == 1 && this.form.customerProblemType == 1) {
			// 		if (this.form.username != '' && this.form.username != null && this.form.username != undefined) {
			// 			this.bkGetPayGameName()
			// 		}
			// 	}

			// 	// }



			// },

			// 折扣玩所有游戏请求
			zkwGetGameName() {
				var _this = this;
				axios.get(process.env.VUE_APP_BASE_API + '/customerServiceZkw/problemInfo/selectKVGameList')
					.then(
						function(response) {
							_this.gameList = JSON.parse(Decrypt(response.data))
							console.log(_this.gameList);
							_this.convert_GameList = JSON.parse(Decrypt(response.data))
							// _this.options = _this.gameList;
						}
					)
					.catch(function(error) {
						console.log(error);
						_this.$message.error('服务正在升级，请稍后再试');
					});
			},

			// 米粒所有游戏请求
			mlyGetGameName() {
				var _this = this
				axios.get(process.env.VUE_APP_BASE_API + '/customerService/problemInfo/selectKVGameList')
					.then(
						function(response) {
							_this.gameList = JSON.parse(Decrypt(response.data))
							// console.log(_this.gameList);
							_this.convert_GameList = JSON.parse(Decrypt(response.data))
							// _this.options = _this.gameList;
						}
					)
					.catch(function(error) {
						console.log(error);
						_this.$message.error('服务正在升级，请稍后再试');
					});
			},
			// 8酷所有游戏请求
			bkGetGameName() {
				var _this = this
				axios.get(process.env.VUE_APP_BASE_API + '/customerServiceEk/problemInfo/selectKVGameList')
					.then(
						function(response) {
							_this.gameList = JSON.parse(Decrypt(response.data))
							// console.log(_this.gameList);
							_this.convert_GameList = JSON.parse(Decrypt(response.data))
							// _this.options = _this.gameList;
						}
					)
					.catch(function(error) {
						console.log(error);
						_this.$message.error('服务正在升级，请稍后再试');
					});
			},
			// 米粒充值游戏请求
			mlyGetPayGameName() {
				var _this = this
				axios.get(process.env.VUE_APP_BASE_API + '/customerService/problemInfo/selectPayKVGameList', {
						params: {
							username: this.form.username,
						}
					})
					.then(
						function(response) {
							// console.log(response);
							if (response.data.code >= 400) {
								_this.$message.error(response.data.msg);
								// console.log(_this.gameList);
								// _this.convert_GameList = JSON.parse(Decrypt(response.data))
								// _this.options = _this.gameList;
							} else {
								_this.gameList = JSON.parse(Decrypt(response.data))

							}


						}
					)
					.catch(function(error) {
						console.log(error);
						// _this.$message.error('服务正在升级，请稍后再试');
					});
			},
			// 8酷充值游戏请求
			bkGetPayGameName() {
				var _this = this
				axios.get(process.env.VUE_APP_BASE_API + '/customerServiceEk/problemInfo/selectPayKVGameList', {
						params: {
							username: this.form.username,
						}
					})
					.then(
						function(response) {
							// console.log(response);
							if (response.data.code >= 400) {
								_this.$message.error(response.data.msg);
								// console.log(_this.gameList);
								// _this.convert_GameList = JSON.parse(Decrypt(response.data))
								// _this.options = _this.gameList;
							} else {
								_this.gameList = JSON.parse(Decrypt(response.data))

							}


						}
					)
					.catch(function(error) {
						console.log(error);
						// _this.$message.error('服务正在升级，请稍后再试');
					});
			},
			// 米粒游小号获取
			mlyGetXiaohao() {
				// console.log(this.form);
				if (this.form.username != '' && this.form.gameId != '' && this.form.username != null && this.form
					.gameId != null) {
					let _this = this;
					axios.get(process.env.VUE_APP_BASE_API + '/customerService/problemInfo/selectGameXiaohaoList', {
							params: {
								username: this.form.username,
								gameId: this.form.gameId,
							}
						})
						.then(
							function(response) {
								// console.log(JSON.parse(Decrypt(response.data)));
								if (response.data.code >= 400) {
									_this.$message.error(response.data.msg);
								} else {
									let xiaohaoList = JSON.parse(Decrypt(response.data))
									if (xiaohaoList.length > 1) {
										_this.xiaohaoOptions = xiaohaoList
									} else if (xiaohaoList.length == 0) {
										_this.$message.error('您在此游戏没有小号')
									} else {
										_this.form.xiaohao = xiaohaoList[0].value
									}
								}

							}
						)
						.catch(function(error) {
							console.log(error);
						});
				} else {
					this.$message.error('必须填写用户名和游戏名才可搜寻小号')
				}
			},
			// 8酷小号获取
			bkGetXiaohao() {
				if (this.form.username != '' && this.form.gameId != '' && this.form.username != null && this.form
					.gameId != null) {
					let _this = this;
					axios.get(process.env.VUE_APP_BASE_API + '/customerServiceEk/problemInfo/selectGameXiaohaoList', {
							params: {
								username: this.form.username,
								gameId: this.form.gameId,
							}
						})
						.then(
							function(response) {
								// console.log(JSON.parse(Decrypt(response.data)));
								if (response.data.code >= 400) {
									_this.$message.error(response.data.msg);
								} else {
									let xiaohaoList = JSON.parse(Decrypt(response.data))
									if (xiaohaoList.length > 1) {
										_this.xiaohaoOptions = xiaohaoList
									} else if (xiaohaoList.length == 0) {
										_this.$message.error('您在此游戏没有小号')
									} else {
										_this.form.xiaohao = xiaohaoList[0].value
									}
								}

							}
						)
						.catch(function(error) {
							console.log(error);
						});
				} else {
					this.$message.error('必须填写用户名和游戏名才可搜寻小号')
				}
			},
		},

	}
</script>